import React, { useState } from 'react';
import './LoginSignup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Popup from './Popup';  // Popup component to show success message

const API_URL = "https://crm-backend-6kqk.onrender.com"; // Replace with your actual API URL

const LoginSignup = () => {
  const [isActive, setIsActive] = useState(false);  // Toggle between login and signup form
  const [formData, setFormData] = useState({ email: '', password: '', name: '', phone: '' });
  const [formErrors, setFormErrors] = useState({});
  const [popupMessage, setPopupMessage] = useState('');  // Message to show in popup

  const handleRegisterClick = () => {
    setIsActive(true);
    setFormErrors({});
    setPopupMessage('');
  };

  const handleLoginClick = () => {
    setIsActive(false);
    setFormErrors({});
    setPopupMessage('');
  };

  // Validate form function (applies to both login and signup)
  const validateForm = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    return errors;
  };

  // Handle Registration API Call
  const registerUser = async () => {
    try {
      const response = await fetch(`${API_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      console.log("Response status:", response.status); // Log response status code
      const responseBody = await response.text(); // Get response body as text
      console.log("Response body:", responseBody); // Log response body

      if (response.ok) {
        const data = JSON.parse(responseBody);
        console.log("Registration successful:", data); // Log success response
        setPopupMessage('Sign Up Successful'); // Show popup for successful signup
        setFormData({ name: '', email: '', phone: '', password: '' });
      } else {
        const error = JSON.parse(responseBody);
        console.log("Registration failed:", error); // Log error response
        setFormErrors({ email: error.message || "Registration failed" });
      }
    } catch (error) {
      console.log("Network error during registration:", error); // Log network errors
      setFormErrors({ email: "An error occurred. Please try again." });
    }
  };

  // Handle Login API Call
  const loginUser = async () => {
    try {
      const response = await fetch(`${API_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, password: formData.password }),
      });

      console.log("Response status:", response.status); // Log response status code
      const responseBody = await response.text(); // Get response body as text
      console.log("Response body:", responseBody); // Log response body

      if (response.ok) {
        const data = JSON.parse(responseBody);
        console.log("Login successful:", data); // Log success response
        setPopupMessage('Login Successful'); // Show popup for successful login
        setFormData({ email: '', password: '' });
      } else {
        const error = JSON.parse(responseBody);
        console.log("Login failed:", error); // Log error response
        setFormErrors({ login: error.message || "Login failed" });
      }
    } catch (error) {
      console.log("Network error during login:", error); // Log network errors
      setFormErrors({ login: "An error occurred. Please try again." });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      if (isActive) {
        registerUser();  // Register User API Call
      } else {
        loginUser();  // Login User API Call
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (name === 'email' && /\S+@\S+\.\S+/.test(value)) {
        delete updatedErrors.email;
      }
      if (name === 'password' && value.length >= 6) {
        delete updatedErrors.password;
      }
      return updatedErrors;
    });
  };

  return (
    <div className={`container ${isActive ? 'active' : ''}`} id="container">
      {/* Show Popup when registration or login is successful */}
      {popupMessage && <Popup message={popupMessage} onClose={() => setPopupMessage('')} />}

      <div className="form-container sign-up">
        <form onSubmit={handleSubmit}>
          <h1>Create Account</h1>
          <div className="social-icons">
            <button className="icon">
              <FontAwesomeIcon icon={faGoogle} />
            </button>
            <button className="icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </button>
          </div>
          <span>or use your email for registration</span>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            {formErrors.name && <p className="error">{formErrors.name}</p>}
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
          </div>
          <div>
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {formErrors.password && <p className="error">{formErrors.password}</p>}
          </div>
          <button type="submit">Sign Up</button>
        </form>
      </div>

      <div className="form-container sign-in">
        <form onSubmit={handleSubmit}>
          <h1>Sign In</h1>
          <div className="social-icons">
            <button className="icon">
              <FontAwesomeIcon icon={faGoogle} />
            </button>
            <button className="icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </button>
          </div>
          <span>or use your email and password</span>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
          </div>
          <div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {formErrors.password && <p className="error">{formErrors.password}</p>}
          </div>
          {formErrors.login && <p className="error">{formErrors.login}</p>}
          <button className="forgot-password">Forget Your Password?</button>
          <button type="submit">Sign In</button>
        </form>
      </div>

      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of the site features</p>
            <button className="hidden" onClick={handleLoginClick}>Sign In</button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1>Hello, Friend!</h1>
            <p>Register with your personal details to use all of the site features</p>
            <button className="hidden" onClick={handleRegisterClick}>Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
