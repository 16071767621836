import React from 'react';
import './popup.css';  // We will add styling here for the popup/modal

const Popup = ({ message, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>{message}</h2>
        <button onClick={onClose} className="popup-close-btn">OK</button>
      </div>
    </div>
  );
};

export default Popup;
